import React from "react"
import NavBar from "./nav-bar"
import SiteMetadata from "./SiteMetaData.js"
import "../styles/tailwind.css"

{/* const Layout = ({ children }) => {
    return (
      <>
        <div>
          <header className="container mx-auto p-8 flex justify-between">
            <div className="w-48">
              <Link to="/">
                  Logo
              </Link>
            </div>
            <div>
              <Link
                activeClassName="border-b border-purple-700"
                className="p-2 mr-4 text-sm hover:text-purple-700"
                to="/blog"
              >
                Blog
              </Link>
            {` `}
              <Link
                to="/user/dashboard"
                className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
              >
                Dashboard
              </Link>
            </div>
          </header>
          <main>{children}</main>
        </div>
      </>
    )
  }
  
  Layout.propTypes = {
    children: PropTypes.node.isRequired,
  }
*/}
const Layout = ({ children }) => (
    <>
      <SiteMetadata />
      <NavBar />
      {children}
    </>
  )

export default Layout