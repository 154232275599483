import React from "react"
import { useState } from "react"

import { Transition } from "@headlessui/react"
import { Link, navigate } from "gatsby"
import { getProfile, isAuthenticated, logout } from "../services/auth"
import styled from "@emotion/styled"

{
  /* From Tailwind: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" */
}
const MenuLink = styled(Link)`
  color: #374151;
  --tw-text-opacity: 1;
  display: block;
  flex-shrink: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  &:hover {
    background: #f3f4f6;
  }
`

export default function NavBar() {
  const user = getProfile()

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div>
        <nav className="bg-amaranth">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Link to="/app">
                    <img
                      className="h-8 w-8"
                      src={require("../images/klevoya_square_shield_logo.png")}
                      alt="App home"
                    />
                  </Link>
                </div>
                {/* TODO: Responsive add mobile menus with new div: <div className="hidden md:block"> */}
                {isAuthenticated() ? (
                  <div>
                    <div className="ml-10 flex items-baseline space-x-4">
                      {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
                      <Link
                        to="/app"
                        className="bg-gray-900 hover:bg-gray-600 text-white px-3 py-2 font-medium text-sm rounded-md"
                      >
                        Dashboard
                      </Link>

                      <a
                        href="#"
                        className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                      >
                        Settings
                      </a>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              {/* TODO: Responsive add mobile menus with new div: <div className="hidden md:block"> */}
              <div className="ml-4 flex items-center md:ml-6">
                {isAuthenticated() ? (
                  /* Notifications/info button */
                  /*<button className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span className="sr-only">View notifications</span>
                   <!-- Heroicon name: bell --> 
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </button>*/

                  /* <!-- Profile dropdown --> */
                  <div className="ml-3 relative">
                    <div>
                      <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                        id="user-menu"
                        aria-haspopup="true"
                      >
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={user.picture}
                          alt=""
                        />
                      </button>
                    </div>
                    {/* <!--
                      Profile dropdown panel, show/hide based on dropdown state.

                      Entering: "transition ease-out duration-100"
                        From: "transform opacity-0 scale-95"
                        To: "transform opacity-100 scale-100"
                      Leaving: "transition ease-in duration-75"
                        From: "transform opacity-100 scale-100"
                        To: "transform opacity-0 scale-95"
                    --> */}
                    <Transition
                      show={isOpen}
                      enter="transition ease-out duration-75"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <div
                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu"
                      >
                        <MenuLink to="/app/profile">Your Profile</MenuLink>
                        <MenuLink to="/app/subscription">Subscription</MenuLink>

                        <a
                          href="/"
                          onClick={event => {
                            event.preventDefault()
                            logout(() => navigate(`/app/login`))
                          }}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                        >
                          Sign out
                        </a>
                      </div>
                    </Transition>
                  </div>
                ) : (
                  /* Not authenticated */
                  <div>
                    <a
                      href="/app"
                      className="bg-pink-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                      Log In / Sign Up
                    </a>
                  </div>
                )}
              </div>
              <div className="-mr-2 flex md:hidden"></div>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}
